<template>
  <div>
    <ics-page-inner title="产品信息">
      <el-col :span="12">
        <el-form-item label="金融产品编码">
          <p>{{ utils.isEffectiveCommon(productDetail.productCode) }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="金融产品名称">
          <p>{{ utils.isEffectiveCommon(productDetail.productName) }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="隶属资方">
          <p>{{ utils.isEffectiveCommon(productDetail.capName) }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="产品类型">
          <p>{{ utils.statusFormat(Number(productDetail.productFinancing), 'productType') }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="产品模型">
          <p>{{ utils.isEffectiveCommon(productDetail.productTypeName) }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="是否支持线上放/还款">
          <p>{{ utils.statusFormat(Number(productDetail.repaymentMethod), 'isOnLine') }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.repayment" :span="12">
        <el-form-item prop="repayment" label="还款方式">
          <p>{{ utils.statusFormat(productDetail.repayment, 'repaymentType') }}</p>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="服务费收取方式">
          <p>{{ utils.statusFormat(Number(productDetail.serviceChargeMethod), 'ownFunds') }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.maxAmount" :span="12">
        <el-form-item label="借贷金额上限(元)">
          <p>{{ utils.moneyFormat(productDetail.maxAmount, 2) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.minCycle" :span="12">
        <el-form-item label="免收费期限(天)">
          <p>{{ utils.isEffectiveCommon(productDetail.minCycle) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.maxPeriod && productDetail.repayment !== 'regular'" :span="12">
        <el-form-item label="额度期限(天)">
          <p>{{ utils.isEffectiveCommon(productDetail.maxPeriod) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.interestRate" :span="12">
        <el-form-item label="日利率(%)">
          <p>{{ utils.isEffectiveCommon(productDetail.interestRate) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.penalTYtRate" :span="12">
        <el-form-item label="罚息(%)">
          <p>{{ utils.isEffectiveCommon(productDetail.penalTYtRate) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.roll" :span="12">
        <el-form-item label="是否支持宽限期">
          <p>{{ utils.statusFormat(productDetail.roll, 'rollType') }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.maxExtension" :span="12">
        <el-form-item label="宽限期最大时长">
          <p>{{ utils.isEffectiveCommon(productDetail.maxExtension) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.overdue" :span="12">
        <el-form-item label="逾期期限">
          <p>{{ utils.isEffectiveCommon(productDetail.overdue) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.extensionRate" :span="12">
        <el-form-item label="宽限期利率(%)">
          <p>{{ utils.isEffectiveCommon(productDetail.extensionRate) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.financing" :span="12">
        <el-form-item label="融资比例(%)">
          <p>{{ utils.isEffectiveCommon(productDetail.financing) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.serviceCharge" :span="12">
        <el-form-item label="服务费比例(%)">
          <p>{{ utils.isEffectiveCommon(productDetail.serviceCharge) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.repayment === 'regular'" :span="12">
        <el-form-item label="期数">
          <p>{{ utils.isEffectiveCommon(productDetail.periods) }}</p>
        </el-form-item>
      </el-col>
      <el-col v-if="productDetail.repayment === 'regular'" :span="12">
        <el-form-item label="还款日：">
          <p>{{ utils.isEffectiveCommon(productDetail.repaymentDay) }}</p>
        </el-form-item>
      </el-col>
    </ics-page-inner>
  </div>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  name: 'IcsProductInner',
  components: {},
  mixins: [routeEnterMixin],
  props: {
    productDetail: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  watch: {
    creditDetail: {
      handler (val) {
        if (val) {
        }
      },
      immediate: true
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
