var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ics-page-inner",
        { attrs: { title: "产品信息" } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "金融产品编码" } }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.utils.isEffectiveCommon(_vm.productDetail.productCode)
                    )
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "金融产品名称" } }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.utils.isEffectiveCommon(_vm.productDetail.productName)
                    )
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "隶属资方" } }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.utils.isEffectiveCommon(_vm.productDetail.capName)
                    )
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "产品类型" } }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.utils.statusFormat(
                        Number(_vm.productDetail.productFinancing),
                        "productType"
                      )
                    )
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "产品模型" } }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.utils.isEffectiveCommon(
                        _vm.productDetail.productTypeName
                      )
                    )
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "是否支持线上放/还款" } }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.utils.statusFormat(
                        Number(_vm.productDetail.repaymentMethod),
                        "isOnLine"
                      )
                    )
                  )
                ])
              ])
            ],
            1
          ),
          _vm.productDetail.repayment
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "repayment", label: "还款方式" } },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.utils.statusFormat(
                              _vm.productDetail.repayment,
                              "repaymentType"
                            )
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "服务费收取方式" } }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.utils.statusFormat(
                        Number(_vm.productDetail.serviceChargeMethod),
                        "ownFunds"
                      )
                    )
                  )
                ])
              ])
            ],
            1
          ),
          _vm.productDetail.maxAmount
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "借贷金额上限(元)" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.moneyFormat(_vm.productDetail.maxAmount, 2)
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.productDetail.minCycle
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "免收费期限(天)" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.productDetail.minCycle
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.productDetail.maxPeriod &&
          _vm.productDetail.repayment !== "regular"
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "额度期限(天)" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.productDetail.maxPeriod
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.productDetail.interestRate
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "日利率(%)" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.productDetail.interestRate
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.productDetail.penalTYtRate
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "罚息(%)" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.productDetail.penalTYtRate
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.productDetail.roll
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "是否支持宽限期" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.statusFormat(
                            _vm.productDetail.roll,
                            "rollType"
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.productDetail.maxExtension
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "宽限期最大时长" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.productDetail.maxExtension
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.productDetail.overdue
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "逾期期限" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(_vm.productDetail.overdue)
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.productDetail.extensionRate
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "宽限期利率(%)" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.productDetail.extensionRate
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.productDetail.financing
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "融资比例(%)" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.productDetail.financing
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.productDetail.serviceCharge
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "服务费比例(%)" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.productDetail.serviceCharge
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.productDetail.repayment === "regular"
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "期数" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(_vm.productDetail.periods)
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.productDetail.repayment === "regular"
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "还款日：" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(
                            _vm.productDetail.repaymentDay
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }